<!-- 出库单管理-查看详情 -->

<template>
  <div class="leaveListDetail">
    <back />
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="productCodeSearch" placeholder="搜索商品编号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productNameSearch" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%;">
          <el-table-column prop="productCode" label="商品编号" width="160"></el-table-column>
          <el-table-column prop="productName" label="商品名称" width="160"></el-table-column>
          <el-table-column prop="schoolName" label="学校名称" width="180"></el-table-column>
          <el-table-column prop="supermarketName" label="商超名称" width="160"></el-table-column>
          <el-table-column prop="scale" label="商品规格"></el-table-column>
          <el-table-column prop="storePrice" label="供货价"></el-table-column>
          <el-table-column prop="number" label="商品数量"></el-table-column>
          <el-table-column prop="price" label="金额"></el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      productCodeSearch: '', // 搜索商品编号
      productNameSearch: '', // 搜索商品名称
      // 表格数据
      tableData: [
        {
          productCode: 'sp001',
          productName: '葡萄',
          schoolName: '广州市第一中学',
          supermarketName: '华润万家',
          scale: '500g',
          storePrice: '2.00',
          number: '3',
          price: '6',
        },
        {
          productCode: 'sp002',
          productName: '苹果',
          schoolName: '广州市第一中学',
          supermarketName: '华润万家',
          scale: '500g',
          storePrice: '2.00',
          number: '3',
          price: '6',
        },
        {
          productCode: 'sp003',
          productName: '香蕉',
          schoolName: '广州市第一中学',
          supermarketName: '华润万家',
          scale: '500g',
          storePrice: '2.00',
          number: '3',
          price: '6',
        },
      ],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    // this.getProductInfoSupplierList();
  },
  methods: {
    // 获取商品列表
    getProductInfoSupplierList() {
      this.loading = true;
      this.$axios.get(this.$api.getProductInfoSupplierList, {
        params: {
          productName: this.searchValue,
          page: this.currentPage,
          pageSize: this.pageSize,
          auditStatus: this.selectValue,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      })
    },

    // 搜索
    onSearch() {
      this.getProductInfoSupplierList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.getProductInfoSupplierList();
    },
    // 换页
    handleCurrentChange(e) {
      this.currentPage = e;
      this.getProductInfoSupplierList();
    },


  }

}



</script>

<style scoped lang="scss">
.leaveListDetail {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }
  }
}
</style>
